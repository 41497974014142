<template>
    <a-layout class="layout-wrapper">
        <a-layout-sider theme="light" class="h-auto pb-0 shadow" v-model:collapsed="collapsed" collapsible>
            <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline" class="sider-menu-wrapper h-100 text-start">
                <a-menu-item class="m-2" key="bigData" @click="$router.push({name: 'bigData'})" :disabled="!env.bigData">
                    <IconSVG name="bigData" />
                    <span class="h6">Data Collection</span>
                </a-menu-item>
                <a-menu-item class="m-2" key="academy" @click="$router.push({name: 'academy'})" :disabled="!env.academy">
                    <IconSVG name="academy" />
                    <span class="h6">Academy</span>
                </a-menu-item>
                <a-menu-item class="m-2" key="eLearning" @click="$router.push({name: 'eLearning'})" :disabled="!env.eLearning">
                    <IconSVG name="eLearning" />
                    <span class="h6">E-Learning</span>
                </a-menu-item>

                <a-menu-item class="m-2" key="kpiReport" @click="$router.push({name: 'kpiReport'})" :disabled="!env.report">
                    <IconSVG name="report" />
                    <span class="h6">KPI Report</span>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout-content class="my-4" style="min-height: 100vh;">
            <router-view></router-view>
        </a-layout-content>
    </a-layout>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, toRef, watch, toRefs, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import IconSVG from "@/components/IconSVG.vue";
import { useAuth } from '@/_modules/auth';
import { Roles } from '@/_utils/types';
export default defineComponent({
    components: {
        IconSVG
    },
    setup() {
        const { account, feature } = useAuth()
        const roles = reactive<Roles>({
			bigData: null,
			academy: null,
			eLearning: null,
            report:null
		})
        const env = reactive({
			bigData: false,
			academy: false,
			eLearning: false,
            report:false
		});
        const collapsed = ref<boolean>(false)
        const route = useRoute()
        const state = reactive({
            selectedKeys: [String(route.name).split("-")[0]]
        })
        watch(() => route.name,
            async newName => {
                state.selectedKeys = [String(newName).split("-")[0]]
            }
        )
        watchEffect(()=>{
            if(account.value){
                const currentAccount = account.value
                roles.bigData = currentAccount?.account_roles?.find(ele => ele.role_featured === 'BIGDATA')?.roles_permission ?? null
                roles.academy = currentAccount?.account_roles?.find(ele => ele.role_featured === 'ACADEMY')?.roles_permission ?? null
                roles.eLearning = currentAccount?.account_roles.find(ele => ele.role_featured === 'ELEARNING')?.roles_permission ?? null
                roles.report = currentAccount?.account_roles.find(ele => ele.role_featured === 'KPI')?.roles_permission ?? null

            }
            env.bigData = !!roles.bigData?.permissions
        env.academy =!!roles.academy?.permissions
        env.eLearning =!!roles.eLearning?.permissions
        env.report =!!roles.report?.permissions
            // if(feature.value) {
            //     const tmp = feature.value?.feature
            //     env.bigData = ["true", true].includes(tmp?.FEATURE_BIGDATA ?? false)
            //     env.academy = ["true", true].includes(tmp?.FEATURE_ACADEMY ?? false)
            //     env.eLearning = ["true", true].includes(tmp?.FEATURE_ELEARNING ?? false)
            //     env.report = ["true", true].includes(tmp?.FEATURE_KPI ?? false)

            // }
        })
        const selectedKeys = toRef(state, 'selectedKeys')

        return {
            collapsed,
            selectedKeys,
            env,
            ...toRefs(roles)
        }
    },
})
</script>
<style scoped lang="scss">
.sider-menu-wrapper{
    &.sat-menu:not(.sat-menu-horizontal){
        :deep(.sat-menu-item-selected), :deep(.sat-menu-item-active){
            background: linear-gradient(46.62deg, #0062FF 0%, #4CB1FB 93.64%);
            color: #FFF
        }
        :deep(.sat-menu-item){
            width: auto;
            border-radius: 5px;
        }
    }
    &.sat-menu-inline-collapsed :deep(.sat-menu-item){
        padding: 0 24px !important;
    }
}
</style>
